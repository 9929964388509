import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel'
import slide1 from "../images/storefront.jpg"
import slide2 from "../images/untitled.png"
import slide3 from "../images/hookah.jpg"
import Multicar from "../components/MultiCar";
import {Redirect} from "@reach/router"

const IndexPage = () => (
  
  <Layout>

{//<Redirect noThrow from="/404"to={"/comingsoon"}/>
}
   
    <SEO title="Home" />
    {/*<h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link>*/}
    <div style={{
      display: 'inline-block',
      height: '60 px',
      width: '100%',
      background: 'yellow',
    }}>
      <br/>
      <h2 style={{
        fontFamily: '',
        textAlign: 'center',
        color: 'red'
      }}>NOW OPEN!</h2></div>
      <div></div>
      <br/>
    <Carousel>
    <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide1}
      alt="First slide"
    />
    <Carousel.Caption>
      <h3 style={{color: 'black'}}>Welcome to our store</h3>
      <p style={{color: 'black'}}>Fresh groceries, meat and produce.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide2}
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3 style={{color: 'black'}}>Meat, cheese and thyme pies</h3>
      <p style={{color: 'black'}}>Made in our bakery.</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide3}
      alt="Third slide"
    />

    <Carousel.Caption>
      <h3 style={{color: 'black'}}>Hookahs and accessories</h3>
    <p style={{color: 'black'}}>{/*TODO:INSERT COMMENT HERE*/}</p>
    </Carousel.Caption>
  </Carousel.Item>
    </Carousel>
    <div></div>
    { 
    /*<div style={{
      display: 'inline-block',
      height: '60 px',
      width: '100%',
      background: 'yellow',
    }}>
    <h2 style={{
        fontFamily: '',
        textAlign: 'center',
        color: 'red'
      }}>Weekly Sales</h2></div>
      <div></div>
      <br/>
  
    //TODO: THIS
    <Multicar/>*/
  }
  </Layout>
)

export default IndexPage
